export default defineNuxtPlugin((nuxtApp) => {
  const { $router } = nuxtApp as Record<string, any>
  $router.options.scrollBehavior = (to: any, from: any) => {
    if (to.hash) {
      return {
        el: to.hash
      }
    }

    if (preventScrollByPopup(to, from)) {
      return
    }

    return { top: 0 }
  }
  useNuxtApp().hook('page:finish', () => {
    setTimeout(() => window.scrollTo(0, -160), 0)
  })
})

const preventScrollByPopup = (to: any, from: any) => {
  if (to.query.popup || from.query.popup) {
    return true
  }
  return false
}
