import { default as bankVNwVCvHRa7Meta } from "/app/pages/account/bank.vue?macro=true";
import { default as bonusHQiFHOkkILMeta } from "/app/pages/account/bonus.vue?macro=true";
import { default as bankp90CTSo5bIMeta } from "/app/pages/account/deposit/bank.vue?macro=true";
import { default as cardfYgUber9UEMeta } from "/app/pages/account/deposit/card.vue?macro=true";
import { default as codepayySJccbxle3Meta } from "/app/pages/account/deposit/codepay.vue?macro=true";
import { default as codepay26kPkvHhx45Meta } from "/app/pages/account/deposit/codepay2.vue?macro=true";
import { default as cryptorb83lTwep2Meta } from "/app/pages/account/deposit/crypto.vue?macro=true";
import { default as ewalletXPvyWSRFI2Meta } from "/app/pages/account/deposit/ewallet.vue?macro=true";
import { default as betqjRbFT7d93Meta } from "/app/pages/account/history/bet.vue?macro=true";
import { default as transactionDaSFnu9qqCMeta } from "/app/pages/account/history/transaction.vue?macro=true";
import { default as historyIR4l0mlf3lMeta } from "/app/pages/account/history.vue?macro=true";
import { default as profilelwi6rEuniiMeta } from "/app/pages/account/profile.vue?macro=true";
import { default as trading_45p2pCTjaVHwZS9Meta } from "/app/pages/account/trading-p2p.vue?macro=true";
import { default as bankE7Aj4PjGcKMeta } from "/app/pages/account/withdraw/bank.vue?macro=true";
import { default as carduwYPqwLbFqMeta } from "/app/pages/account/withdraw/card.vue?macro=true";
import { default as cryptoiglDDR382EMeta } from "/app/pages/account/withdraw/crypto.vue?macro=true";
import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as base_45buttonxnvxOj7VB9Meta } from "/app/pages/dev/base-button.vue?macro=true";
import { default as base_45count_45down8LTJoNsZP2Meta } from "/app/pages/dev/base-count-down.vue?macro=true";
import { default as base_45dropdown_45radioWyQQWzrifhMeta } from "/app/pages/dev/base-dropdown-radio.vue?macro=true";
import { default as base_45imgfwohlXj8ueMeta } from "/app/pages/dev/base-img.vue?macro=true";
import { default as base_45inputTZhiIjpwyCMeta } from "/app/pages/dev/base-input.vue?macro=true";
import { default as base_45loadingFST7Z1GeBuMeta } from "/app/pages/dev/base-loading.vue?macro=true";
import { default as base_45modal0rdnL4flOnMeta } from "/app/pages/dev/base-modal.vue?macro=true";
import { default as base_45money_45inputOhPcQd7FFyMeta } from "/app/pages/dev/base-money-input.vue?macro=true";
import { default as base_45paginationvpZjCMO86DMeta } from "/app/pages/dev/base-pagination.vue?macro=true";
import { default as base_45select_45bankSLtbYZf7znMeta } from "/app/pages/dev/base-select-bank.vue?macro=true";
import { default as game_45itemQiI8AbQlYUMeta } from "/app/pages/dev/game-item.vue?macro=true";
import { default as indexxBIcZsbwmtMeta } from "/app/pages/dev/index.vue?macro=true";
import { default as _91type_93O82o5Bg1mJMeta } from "/app/pages/game/[type].vue?macro=true";
import { default as gameAUIqB7YSRXMeta } from "/app/pages/game.vue?macro=true";
import { default as _91alias_93cNma8ncfXwMeta } from "/app/pages/gioi-thieu/[alias].vue?macro=true";
import { default as gioi_45thieuTRBpuz5fnPMeta } from "/app/pages/gioi-thieu.vue?macro=true";
import { default as _91alias_93xEYvTtsnebMeta } from "/app/pages/huong-dan/[alias].vue?macro=true";
import { default as huong_45dana5b1MIsVsoMeta } from "/app/pages/huong-dan.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91id_93eKgMdRE7u5Meta } from "/app/pages/khuyen-mai/[id].vue?macro=true";
import { default as indexU63FkvwwD6Meta } from "/app/pages/khuyen-mai/index.vue?macro=true";
import { default as _91id_930WOvRIqqKoMeta } from "/app/pages/khuyen-mai/the-loai/[id].vue?macro=true";
import { default as viphQ4gW2cg55Meta } from "/app/pages/khuyen-mai/vip.vue?macro=true";
import { default as khuyen_45maiymuAf9mjSDMeta } from "/app/pages/khuyen-mai.vue?macro=true";
import { default as _91type_93fb9UIXxk88Meta } from "/app/pages/livecasino/[type].vue?macro=true";
import { default as livecasino5xobui7aDXMeta } from "/app/pages/livecasino.vue?macro=true";
import { default as reset_45password52TOS9kgCUMeta } from "/app/pages/reset-password.vue?macro=true";
import { default as _91id_93zUoNMvdBGwMeta } from "/app/pages/the-thao/[id].vue?macro=true";
import { default as index87hwsH6XLIMeta } from "/app/pages/the-thao/index.vue?macro=true";
import { default as indexnq5tM8dd4yMeta } from "/app/pages/the-thao/lich-thi-dau/index.vue?macro=true";
import { default as _91alias_937bk6WznJMxMeta } from "/app/pages/tin-tuc/[alias].vue?macro=true";
import { default as indexVwkDLY6yaEMeta } from "/app/pages/tin-tuc/index.vue?macro=true";
import { default as _91Slug_93p9AtSKhlaqMeta } from "/app/pages/tin-tuc/the-loai/[Slug].vue?macro=true";
import { default as tin_45tuciAnDOfVzqcMeta } from "/app/pages/tin-tuc.vue?macro=true";
import { default as verify_45mailsrbo6UhAspMeta } from "/app/pages/verify-mail.vue?macro=true";
export default [
  {
    name: accountIWnKuvcAuMMeta?.name ?? "account",
    path: accountIWnKuvcAuMMeta?.path ?? "/account",
    children: [
  {
    name: bankVNwVCvHRa7Meta?.name ?? "account-bank",
    path: bankVNwVCvHRa7Meta?.path ?? "bank",
    meta: bankVNwVCvHRa7Meta || {},
    alias: bankVNwVCvHRa7Meta?.alias || [],
    redirect: bankVNwVCvHRa7Meta?.redirect || undefined,
    component: () => import("/app/pages/account/bank.vue").then(m => m.default || m)
  },
  {
    name: bonusHQiFHOkkILMeta?.name ?? "account-bonus",
    path: bonusHQiFHOkkILMeta?.path ?? "bonus",
    meta: bonusHQiFHOkkILMeta || {},
    alias: bonusHQiFHOkkILMeta?.alias || [],
    redirect: bonusHQiFHOkkILMeta?.redirect || undefined,
    component: () => import("/app/pages/account/bonus.vue").then(m => m.default || m)
  },
  {
    name: bankp90CTSo5bIMeta?.name ?? "account-deposit-bank",
    path: bankp90CTSo5bIMeta?.path ?? "deposit/bank",
    meta: bankp90CTSo5bIMeta || {},
    alias: bankp90CTSo5bIMeta?.alias || [],
    redirect: bankp90CTSo5bIMeta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/bank.vue").then(m => m.default || m)
  },
  {
    name: cardfYgUber9UEMeta?.name ?? "account-deposit-card",
    path: cardfYgUber9UEMeta?.path ?? "deposit/card",
    meta: cardfYgUber9UEMeta || {},
    alias: cardfYgUber9UEMeta?.alias || [],
    redirect: cardfYgUber9UEMeta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/card.vue").then(m => m.default || m)
  },
  {
    name: codepayySJccbxle3Meta?.name ?? "account-deposit-codepay",
    path: codepayySJccbxle3Meta?.path ?? "deposit/codepay",
    meta: codepayySJccbxle3Meta || {},
    alias: codepayySJccbxle3Meta?.alias || [],
    redirect: codepayySJccbxle3Meta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/codepay.vue").then(m => m.default || m)
  },
  {
    name: codepay26kPkvHhx45Meta?.name ?? "account-deposit-codepay2",
    path: codepay26kPkvHhx45Meta?.path ?? "deposit/codepay2",
    meta: codepay26kPkvHhx45Meta || {},
    alias: codepay26kPkvHhx45Meta?.alias || [],
    redirect: codepay26kPkvHhx45Meta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/codepay2.vue").then(m => m.default || m)
  },
  {
    name: cryptorb83lTwep2Meta?.name ?? "account-deposit-crypto",
    path: cryptorb83lTwep2Meta?.path ?? "deposit/crypto",
    meta: cryptorb83lTwep2Meta || {},
    alias: cryptorb83lTwep2Meta?.alias || [],
    redirect: cryptorb83lTwep2Meta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/crypto.vue").then(m => m.default || m)
  },
  {
    name: ewalletXPvyWSRFI2Meta?.name ?? "account-deposit-ewallet",
    path: ewalletXPvyWSRFI2Meta?.path ?? "deposit/ewallet",
    meta: ewalletXPvyWSRFI2Meta || {},
    alias: ewalletXPvyWSRFI2Meta?.alias || [],
    redirect: ewalletXPvyWSRFI2Meta?.redirect || undefined,
    component: () => import("/app/pages/account/deposit/ewallet.vue").then(m => m.default || m)
  },
  {
    name: historyIR4l0mlf3lMeta?.name ?? "account-history",
    path: historyIR4l0mlf3lMeta?.path ?? "history",
    children: [
  {
    name: betqjRbFT7d93Meta?.name ?? "account-history-bet",
    path: betqjRbFT7d93Meta?.path ?? "bet",
    meta: betqjRbFT7d93Meta || {},
    alias: betqjRbFT7d93Meta?.alias || [],
    redirect: betqjRbFT7d93Meta?.redirect || undefined,
    component: () => import("/app/pages/account/history/bet.vue").then(m => m.default || m)
  },
  {
    name: transactionDaSFnu9qqCMeta?.name ?? "account-history-transaction",
    path: transactionDaSFnu9qqCMeta?.path ?? "transaction",
    meta: transactionDaSFnu9qqCMeta || {},
    alias: transactionDaSFnu9qqCMeta?.alias || [],
    redirect: transactionDaSFnu9qqCMeta?.redirect || undefined,
    component: () => import("/app/pages/account/history/transaction.vue").then(m => m.default || m)
  }
],
    meta: historyIR4l0mlf3lMeta || {},
    alias: historyIR4l0mlf3lMeta?.alias || [],
    redirect: historyIR4l0mlf3lMeta?.redirect || undefined,
    component: () => import("/app/pages/account/history.vue").then(m => m.default || m)
  },
  {
    name: profilelwi6rEuniiMeta?.name ?? "account-profile",
    path: profilelwi6rEuniiMeta?.path ?? "profile",
    meta: profilelwi6rEuniiMeta || {},
    alias: profilelwi6rEuniiMeta?.alias || [],
    redirect: profilelwi6rEuniiMeta?.redirect || undefined,
    component: () => import("/app/pages/account/profile.vue").then(m => m.default || m)
  },
  {
    name: trading_45p2pCTjaVHwZS9Meta?.name ?? "account-trading-p2p",
    path: trading_45p2pCTjaVHwZS9Meta?.path ?? "trading-p2p",
    meta: trading_45p2pCTjaVHwZS9Meta || {},
    alias: trading_45p2pCTjaVHwZS9Meta?.alias || [],
    redirect: trading_45p2pCTjaVHwZS9Meta?.redirect || undefined,
    component: () => import("/app/pages/account/trading-p2p.vue").then(m => m.default || m)
  },
  {
    name: bankE7Aj4PjGcKMeta?.name ?? "account-withdraw-bank",
    path: bankE7Aj4PjGcKMeta?.path ?? "withdraw/bank",
    meta: bankE7Aj4PjGcKMeta || {},
    alias: bankE7Aj4PjGcKMeta?.alias || [],
    redirect: bankE7Aj4PjGcKMeta?.redirect || undefined,
    component: () => import("/app/pages/account/withdraw/bank.vue").then(m => m.default || m)
  },
  {
    name: carduwYPqwLbFqMeta?.name ?? "account-withdraw-card",
    path: carduwYPqwLbFqMeta?.path ?? "withdraw/card",
    meta: carduwYPqwLbFqMeta || {},
    alias: carduwYPqwLbFqMeta?.alias || [],
    redirect: carduwYPqwLbFqMeta?.redirect || undefined,
    component: () => import("/app/pages/account/withdraw/card.vue").then(m => m.default || m)
  },
  {
    name: cryptoiglDDR382EMeta?.name ?? "account-withdraw-crypto",
    path: cryptoiglDDR382EMeta?.path ?? "withdraw/crypto",
    meta: cryptoiglDDR382EMeta || {},
    alias: cryptoiglDDR382EMeta?.alias || [],
    redirect: cryptoiglDDR382EMeta?.redirect || undefined,
    component: () => import("/app/pages/account/withdraw/crypto.vue").then(m => m.default || m)
  }
],
    meta: accountIWnKuvcAuMMeta || {},
    alias: accountIWnKuvcAuMMeta?.alias || [],
    redirect: accountIWnKuvcAuMMeta?.redirect || undefined,
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: base_45buttonxnvxOj7VB9Meta?.name ?? "dev-base-button",
    path: base_45buttonxnvxOj7VB9Meta?.path ?? "/dev/base-button",
    meta: base_45buttonxnvxOj7VB9Meta || {},
    alias: base_45buttonxnvxOj7VB9Meta?.alias || [],
    redirect: base_45buttonxnvxOj7VB9Meta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-button.vue").then(m => m.default || m)
  },
  {
    name: base_45count_45down8LTJoNsZP2Meta?.name ?? "dev-base-count-down",
    path: base_45count_45down8LTJoNsZP2Meta?.path ?? "/dev/base-count-down",
    meta: base_45count_45down8LTJoNsZP2Meta || {},
    alias: base_45count_45down8LTJoNsZP2Meta?.alias || [],
    redirect: base_45count_45down8LTJoNsZP2Meta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-count-down.vue").then(m => m.default || m)
  },
  {
    name: base_45dropdown_45radioWyQQWzrifhMeta?.name ?? "dev-base-dropdown-radio",
    path: base_45dropdown_45radioWyQQWzrifhMeta?.path ?? "/dev/base-dropdown-radio",
    meta: base_45dropdown_45radioWyQQWzrifhMeta || {},
    alias: base_45dropdown_45radioWyQQWzrifhMeta?.alias || [],
    redirect: base_45dropdown_45radioWyQQWzrifhMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-dropdown-radio.vue").then(m => m.default || m)
  },
  {
    name: base_45imgfwohlXj8ueMeta?.name ?? "dev-base-img",
    path: base_45imgfwohlXj8ueMeta?.path ?? "/dev/base-img",
    meta: base_45imgfwohlXj8ueMeta || {},
    alias: base_45imgfwohlXj8ueMeta?.alias || [],
    redirect: base_45imgfwohlXj8ueMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-img.vue").then(m => m.default || m)
  },
  {
    name: base_45inputTZhiIjpwyCMeta?.name ?? "dev-base-input",
    path: base_45inputTZhiIjpwyCMeta?.path ?? "/dev/base-input",
    meta: base_45inputTZhiIjpwyCMeta || {},
    alias: base_45inputTZhiIjpwyCMeta?.alias || [],
    redirect: base_45inputTZhiIjpwyCMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-input.vue").then(m => m.default || m)
  },
  {
    name: base_45loadingFST7Z1GeBuMeta?.name ?? "dev-base-loading",
    path: base_45loadingFST7Z1GeBuMeta?.path ?? "/dev/base-loading",
    meta: base_45loadingFST7Z1GeBuMeta || {},
    alias: base_45loadingFST7Z1GeBuMeta?.alias || [],
    redirect: base_45loadingFST7Z1GeBuMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-loading.vue").then(m => m.default || m)
  },
  {
    name: base_45modal0rdnL4flOnMeta?.name ?? "dev-base-modal",
    path: base_45modal0rdnL4flOnMeta?.path ?? "/dev/base-modal",
    meta: base_45modal0rdnL4flOnMeta || {},
    alias: base_45modal0rdnL4flOnMeta?.alias || [],
    redirect: base_45modal0rdnL4flOnMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-modal.vue").then(m => m.default || m)
  },
  {
    name: base_45money_45inputOhPcQd7FFyMeta?.name ?? "dev-base-money-input",
    path: base_45money_45inputOhPcQd7FFyMeta?.path ?? "/dev/base-money-input",
    meta: base_45money_45inputOhPcQd7FFyMeta || {},
    alias: base_45money_45inputOhPcQd7FFyMeta?.alias || [],
    redirect: base_45money_45inputOhPcQd7FFyMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-money-input.vue").then(m => m.default || m)
  },
  {
    name: base_45paginationvpZjCMO86DMeta?.name ?? "dev-base-pagination",
    path: base_45paginationvpZjCMO86DMeta?.path ?? "/dev/base-pagination",
    meta: base_45paginationvpZjCMO86DMeta || {},
    alias: base_45paginationvpZjCMO86DMeta?.alias || [],
    redirect: base_45paginationvpZjCMO86DMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-pagination.vue").then(m => m.default || m)
  },
  {
    name: base_45select_45bankSLtbYZf7znMeta?.name ?? "dev-base-select-bank",
    path: base_45select_45bankSLtbYZf7znMeta?.path ?? "/dev/base-select-bank",
    meta: base_45select_45bankSLtbYZf7znMeta || {},
    alias: base_45select_45bankSLtbYZf7znMeta?.alias || [],
    redirect: base_45select_45bankSLtbYZf7znMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/base-select-bank.vue").then(m => m.default || m)
  },
  {
    name: game_45itemQiI8AbQlYUMeta?.name ?? "dev-game-item",
    path: game_45itemQiI8AbQlYUMeta?.path ?? "/dev/game-item",
    meta: game_45itemQiI8AbQlYUMeta || {},
    alias: game_45itemQiI8AbQlYUMeta?.alias || [],
    redirect: game_45itemQiI8AbQlYUMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/game-item.vue").then(m => m.default || m)
  },
  {
    name: indexxBIcZsbwmtMeta?.name ?? "dev",
    path: indexxBIcZsbwmtMeta?.path ?? "/dev",
    meta: indexxBIcZsbwmtMeta || {},
    alias: indexxBIcZsbwmtMeta?.alias || [],
    redirect: indexxBIcZsbwmtMeta?.redirect || undefined,
    component: () => import("/app/pages/dev/index.vue").then(m => m.default || m)
  },
  {
    name: gameAUIqB7YSRXMeta?.name ?? "game",
    path: gameAUIqB7YSRXMeta?.path ?? "/game",
    children: [
  {
    name: _91type_93O82o5Bg1mJMeta?.name ?? "game-type",
    path: _91type_93O82o5Bg1mJMeta?.path ?? ":type()",
    meta: _91type_93O82o5Bg1mJMeta || {},
    alias: _91type_93O82o5Bg1mJMeta?.alias || [],
    redirect: _91type_93O82o5Bg1mJMeta?.redirect || undefined,
    component: () => import("/app/pages/game/[type].vue").then(m => m.default || m)
  }
],
    meta: gameAUIqB7YSRXMeta || {},
    alias: gameAUIqB7YSRXMeta?.alias || [],
    redirect: gameAUIqB7YSRXMeta?.redirect || undefined,
    component: () => import("/app/pages/game.vue").then(m => m.default || m)
  },
  {
    name: gioi_45thieuTRBpuz5fnPMeta?.name ?? "gioi-thieu",
    path: gioi_45thieuTRBpuz5fnPMeta?.path ?? "/gioi-thieu",
    children: [
  {
    name: _91alias_93cNma8ncfXwMeta?.name ?? "gioi-thieu-alias",
    path: _91alias_93cNma8ncfXwMeta?.path ?? ":alias()",
    meta: _91alias_93cNma8ncfXwMeta || {},
    alias: _91alias_93cNma8ncfXwMeta?.alias || [],
    redirect: _91alias_93cNma8ncfXwMeta?.redirect || undefined,
    component: () => import("/app/pages/gioi-thieu/[alias].vue").then(m => m.default || m)
  }
],
    meta: gioi_45thieuTRBpuz5fnPMeta || {},
    alias: gioi_45thieuTRBpuz5fnPMeta?.alias || [],
    redirect: gioi_45thieuTRBpuz5fnPMeta?.redirect || undefined,
    component: () => import("/app/pages/gioi-thieu.vue").then(m => m.default || m)
  },
  {
    name: huong_45dana5b1MIsVsoMeta?.name ?? "huong-dan",
    path: huong_45dana5b1MIsVsoMeta?.path ?? "/huong-dan",
    children: [
  {
    name: _91alias_93xEYvTtsnebMeta?.name ?? "huong-dan-alias",
    path: _91alias_93xEYvTtsnebMeta?.path ?? ":alias()",
    meta: _91alias_93xEYvTtsnebMeta || {},
    alias: _91alias_93xEYvTtsnebMeta?.alias || [],
    redirect: _91alias_93xEYvTtsnebMeta?.redirect || undefined,
    component: () => import("/app/pages/huong-dan/[alias].vue").then(m => m.default || m)
  }
],
    meta: huong_45dana5b1MIsVsoMeta || {},
    alias: huong_45dana5b1MIsVsoMeta?.alias || [],
    redirect: huong_45dana5b1MIsVsoMeta?.redirect || undefined,
    component: () => import("/app/pages/huong-dan.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect || undefined,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    path: khuyen_45maiymuAf9mjSDMeta?.path ?? "/khuyen-mai",
    children: [
  {
    name: _91id_93eKgMdRE7u5Meta?.name ?? "khuyen-mai-id",
    path: _91id_93eKgMdRE7u5Meta?.path ?? ":id()",
    meta: _91id_93eKgMdRE7u5Meta || {},
    alias: _91id_93eKgMdRE7u5Meta?.alias || [],
    redirect: _91id_93eKgMdRE7u5Meta?.redirect || undefined,
    component: () => import("/app/pages/khuyen-mai/[id].vue").then(m => m.default || m)
  },
  {
    name: indexU63FkvwwD6Meta?.name ?? "khuyen-mai",
    path: indexU63FkvwwD6Meta?.path ?? "",
    meta: indexU63FkvwwD6Meta || {},
    alias: indexU63FkvwwD6Meta?.alias || [],
    redirect: indexU63FkvwwD6Meta?.redirect || undefined,
    component: () => import("/app/pages/khuyen-mai/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930WOvRIqqKoMeta?.name ?? "khuyen-mai-the-loai-id",
    path: _91id_930WOvRIqqKoMeta?.path ?? "the-loai/:id()",
    meta: _91id_930WOvRIqqKoMeta || {},
    alias: _91id_930WOvRIqqKoMeta?.alias || [],
    redirect: _91id_930WOvRIqqKoMeta?.redirect || undefined,
    component: () => import("/app/pages/khuyen-mai/the-loai/[id].vue").then(m => m.default || m)
  },
  {
    name: viphQ4gW2cg55Meta?.name ?? "khuyen-mai-vip",
    path: viphQ4gW2cg55Meta?.path ?? "vip",
    meta: viphQ4gW2cg55Meta || {},
    alias: viphQ4gW2cg55Meta?.alias || [],
    redirect: viphQ4gW2cg55Meta?.redirect || undefined,
    component: () => import("/app/pages/khuyen-mai/vip.vue").then(m => m.default || m)
  }
],
    name: khuyen_45maiymuAf9mjSDMeta?.name ?? undefined,
    meta: khuyen_45maiymuAf9mjSDMeta || {},
    alias: khuyen_45maiymuAf9mjSDMeta?.alias || [],
    redirect: khuyen_45maiymuAf9mjSDMeta?.redirect || undefined,
    component: () => import("/app/pages/khuyen-mai.vue").then(m => m.default || m)
  },
  {
    name: livecasino5xobui7aDXMeta?.name ?? "livecasino",
    path: livecasino5xobui7aDXMeta?.path ?? "/livecasino",
    children: [
  {
    name: _91type_93fb9UIXxk88Meta?.name ?? "livecasino-type",
    path: _91type_93fb9UIXxk88Meta?.path ?? ":type()",
    meta: _91type_93fb9UIXxk88Meta || {},
    alias: _91type_93fb9UIXxk88Meta?.alias || [],
    redirect: _91type_93fb9UIXxk88Meta?.redirect || undefined,
    component: () => import("/app/pages/livecasino/[type].vue").then(m => m.default || m)
  }
],
    meta: livecasino5xobui7aDXMeta || {},
    alias: livecasino5xobui7aDXMeta?.alias || [],
    redirect: livecasino5xobui7aDXMeta?.redirect || undefined,
    component: () => import("/app/pages/livecasino.vue").then(m => m.default || m)
  },
  {
    name: reset_45password52TOS9kgCUMeta?.name ?? "reset-password",
    path: reset_45password52TOS9kgCUMeta?.path ?? "/reset-password",
    meta: reset_45password52TOS9kgCUMeta || {},
    alias: reset_45password52TOS9kgCUMeta?.alias || [],
    redirect: reset_45password52TOS9kgCUMeta?.redirect || undefined,
    component: () => import("/app/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: _91id_93zUoNMvdBGwMeta?.name ?? "the-thao-id",
    path: _91id_93zUoNMvdBGwMeta?.path ?? "/the-thao/:id()",
    meta: _91id_93zUoNMvdBGwMeta || {},
    alias: _91id_93zUoNMvdBGwMeta?.alias || [],
    redirect: _91id_93zUoNMvdBGwMeta?.redirect || undefined,
    component: () => import("/app/pages/the-thao/[id].vue").then(m => m.default || m)
  },
  {
    name: index87hwsH6XLIMeta?.name ?? "the-thao",
    path: index87hwsH6XLIMeta?.path ?? "/the-thao",
    meta: index87hwsH6XLIMeta || {},
    alias: index87hwsH6XLIMeta?.alias || [],
    redirect: index87hwsH6XLIMeta?.redirect || undefined,
    component: () => import("/app/pages/the-thao/index.vue").then(m => m.default || m)
  },
  {
    name: indexnq5tM8dd4yMeta?.name ?? "the-thao-lich-thi-dau",
    path: indexnq5tM8dd4yMeta?.path ?? "/the-thao/lich-thi-dau",
    meta: indexnq5tM8dd4yMeta || {},
    alias: indexnq5tM8dd4yMeta?.alias || [],
    redirect: indexnq5tM8dd4yMeta?.redirect || undefined,
    component: () => import("/app/pages/the-thao/lich-thi-dau/index.vue").then(m => m.default || m)
  },
  {
    path: tin_45tuciAnDOfVzqcMeta?.path ?? "/tin-tuc",
    children: [
  {
    name: _91alias_937bk6WznJMxMeta?.name ?? "tin-tuc-alias",
    path: _91alias_937bk6WznJMxMeta?.path ?? ":alias()",
    meta: _91alias_937bk6WznJMxMeta || {},
    alias: _91alias_937bk6WznJMxMeta?.alias || [],
    redirect: _91alias_937bk6WznJMxMeta?.redirect || undefined,
    component: () => import("/app/pages/tin-tuc/[alias].vue").then(m => m.default || m)
  },
  {
    name: indexVwkDLY6yaEMeta?.name ?? "tin-tuc",
    path: indexVwkDLY6yaEMeta?.path ?? "",
    meta: indexVwkDLY6yaEMeta || {},
    alias: indexVwkDLY6yaEMeta?.alias || [],
    redirect: indexVwkDLY6yaEMeta?.redirect || undefined,
    component: () => import("/app/pages/tin-tuc/index.vue").then(m => m.default || m)
  },
  {
    name: _91Slug_93p9AtSKhlaqMeta?.name ?? "tin-tuc-the-loai-Slug",
    path: _91Slug_93p9AtSKhlaqMeta?.path ?? "the-loai/:Slug()",
    meta: _91Slug_93p9AtSKhlaqMeta || {},
    alias: _91Slug_93p9AtSKhlaqMeta?.alias || [],
    redirect: _91Slug_93p9AtSKhlaqMeta?.redirect || undefined,
    component: () => import("/app/pages/tin-tuc/the-loai/[Slug].vue").then(m => m.default || m)
  }
],
    name: tin_45tuciAnDOfVzqcMeta?.name ?? undefined,
    meta: tin_45tuciAnDOfVzqcMeta || {},
    alias: tin_45tuciAnDOfVzqcMeta?.alias || [],
    redirect: tin_45tuciAnDOfVzqcMeta?.redirect || undefined,
    component: () => import("/app/pages/tin-tuc.vue").then(m => m.default || m)
  },
  {
    name: verify_45mailsrbo6UhAspMeta?.name ?? "verify-mail",
    path: verify_45mailsrbo6UhAspMeta?.path ?? "/verify-mail",
    meta: verify_45mailsrbo6UhAspMeta || {},
    alias: verify_45mailsrbo6UhAspMeta?.alias || [],
    redirect: verify_45mailsrbo6UhAspMeta?.redirect || undefined,
    component: () => import("/app/pages/verify-mail.vue").then(m => m.default || m)
  }
]